<template>
  <v-container class="">
    <v-row>
      <v-col cols="12">
        <div class="box-wrapper">
          <div
            class="box-overlay"
            :class="{'open': isSidebar}"
            @click="isSidebar = !isSidebar"
          />
          <div
            v-if="categories.length"
            class="box-sidebar pb-3 shadow-sm"
            :class="{'open': isSidebar}"
          >
            <h5 class="ps-6 mt-6 d-flex">
              {{ $t("Categories") }}
              <v-btn
                v-if="filter.categories.length > 0"
                small
                color="primary"
                depressed
                text
                @click.stop="filter.categories=[]"
              >
                {{ $t("clear") }}
              </v-btn>
            </h5>

            <v-list
              dense
              class="filter"
            >
              <template v-for="cat in categories">
                <v-list-group
                  v-if="cat.categeries && cat.categeries.length"
                  value="true"
                  class=" small-list"
                >
                  <template v-slot:activator>
                    <v-list-item-title>{{ cat.name }}</v-list-item-title>
                  </template>

                  <template v-for="(c,indexCat) in cat.categories">
                    <v-list-item
                      :key="c.id"
                      dense
                      :to="`/categories/${c.slug}`"
                    >
                      <!--                      <v-list-item-action>
                        <v-checkbox
                          v-model="filter.categories"
                          dense
                          :value="c.id"
                        />
                      </v-list-item-action>-->

                      <v-list-item-content>
                        <v-list-item-title>{{ c.name }}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn
                          icon
                          small
                        >
                          <v-icon>mdi-chevron-right</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                </v-list-group>
                <v-list-item
                  v-else
                  :to="`/categories/${cat.slug}`"
                  dense
                >
                  <!--                  <v-list-item-action>
                    <v-checkbox
                      v-model="filter.categories"
                      dense
                      :value="cat.id"
                    />
                  </v-list-item-action>-->

                  <v-list-item-content>
                    <v-list-item-title>{{ cat.name }}</v-list-item-title>
                  </v-list-item-content>
                  <!--                  <v-list-item-action>
                    <v-btn
                      icon
                      small
                      :href="`/categories/${cat.slug}`"
                    >
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-list-item-action>-->
                </v-list-item>
              </template>
            </v-list>
          </div>
          <div :class="{'box-content': categories.length}">
            <div class="box-container">
              <v-row>
                <v-col cols="12">
                  <!--                  <v-progress-linear
                    v-if="loading"
                    indeterminate
                  />-->
                  <base-card id="products">
                    <v-card-text class="ps-md-6 ps-2 d-flex justify-space-between align-center flex-wrap">
                      <v-btn
                        icon
                        class="d-md-none "
                        style="width: 14px;"
                        @click="isSidebar = !isSidebar"
                      >
                        <v-icon dark>
                          mdi-format-list-bulleted-square
                        </v-icon>
                      </v-btn>
                      <div class="my-2">
                        <h4
                          v-if="query && query.trim.length && query.length>2"
                          class=""
                        >
                          {{ $t("Recherche de") }} "{{ query }}"
                        </h4>
                        <p
                          v-if="!loading"
                          class="gray--text text--darken-1 mb-0"
                        >
                          {{ pagination.totalItems || 0 }} {{ $t("résultats trouvés") }}
                        </p>
                        <p
                          v-else
                          class="gray--text text--darken-1 mb-0"
                        >
                          {{ $t("Chargement") }}...
                        </p>
                      </div>
                    </v-card-text>
                  </base-card>
                </v-col>
                <v-col
                  v-if="filter.categories && filter.categories.length"
                  cols="12"
                >
                  <v-chip
                    v-for="cat in categories.filter((el) => filter.categories.includes(el.id))"
                    outlined
                    class="mr-2"
                    small
                    close
                    @click:close="filter.categories = filter.categories.filter(item => item !== cat.id)"
                  >
                    {{ cat.name }}
                  </v-chip>
                </v-col>
                <v-col
                  v-for="(item, index) in items"
                  :key="index"
                  cols="6"
                  sm="6"
                  md="6"
                  :lg="categories.length?4:3"
                  :xl="categories.length?4:3"
                >
                  <card-cart
                    :content-img="item.img"
                    :content-text="item.title"
                    :counter="item.qty"
                    class="m-5"
                    :product="item"
                    @cartRemove="removeCart(item)"
                    @cartAdd="addCart(item)"
                  />
                </v-col>
                <v-col
                  v-for="sk in [1,2,3,4,5,6,7,8,9,10]"
                  v-if="loading"
                  :key="sk"
                  cols="6"
                  sm="6"
                  md="6"
                  :lg="categories.length?4:3"
                  :xl="categories.length?4:3"
                >
                  <template>
                    <v-skeleton-loader
                      type="image"
                    />
                    <v-skeleton-loader
                      type="article"
                      height="150"
                    />
                  </template>
                </v-col>
                <v-col cols="12">
                  <!--                  <v-data-iterator
                    :items="items"
                    :items-per-page.sync="itemsPerPage"
                    :page.sync="page"
                    hide-default-footer
                  >
                    <template v-slot:default="props">
                      <v-row>
                        <v-col
                          v-for="(item, index) in props.items"
                          :key="index"
                          cols="12"
                          sm="6"
                          md="6"
                          lg="4"
                          xl="3"
                        >
                          <card-cart
                            :content-img="item.img"
                            :content-text="item.title"
                            :amount="item.amount"
                            :content-rating="item.rating"
                            :counter="item.qty"
                            @cartRemove="removeCart(item)"
                            @cartAdd="addCart(item)"
                          />
                        </v-col>
                      </v-row>
                    </template>

                    <template v-slot:footer>
                      <v-row
                        class="my-5 me-1"
                        align="center"
                        justify="center"
                      >
                        <v-spacer />

                        <span
                          class="mr-4
                                        grey&#45;&#45;text"
                        >
                          Page {{ page }} of {{ numberOfPages }}
                        </span>
                        <v-btn
                          fab
                          :disabled="page == 1"
                          small
                          color="primary"
                          class="mr-1"

                          @click="formerPage"
                        >
                          <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <v-btn
                          fab

                          :disabled="page == numberOfPages"
                          small
                          color="primary"
                          class="ml-1"
                          @click="nextPage"
                        >
                          <v-icon>mdi-chevron-right</v-icon>
                        </v-btn>
                      </v-row>
                    </template>
                  </v-data-iterator>-->
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters, mapActions, mapMutations } from 'vuex'
  import CardCart from '@/components/cartCard/CardCart'
  import { i18n } from '../../plugins/i18n'
  export default {
    components: {
      CardCart,
    },
    data () {
      return {
        isSidebar: false,
        page: 1,
        itemsPerPage: 8,
        items: [],
        range: [0, 100, 500, 1000],
        itemsTwo: ['Low to High', 'High to Low'],
        select: '',
        cartCount: 0,
        total: 0,
        loading: false,
        pagination: { totalItems: 0 },
        selection: 1,
        dialog: false,
        chat_dialog: false,
        view_dialog: false,
        confirm: false,
        confirmObject: { action: '', text: '', active: false },
        snackbar: { active: false, text: '', color: 'primary' },
        showFirstLastPage: true,
        showCurrentPage: true,
        tab: null,
        default_url: '/search',
        url: '/search',
        query: '',
        awaitingSearch: '',
        bottom: false,
        timeout: null,
        filterProductList: [],
        filterProduct: [],
        filter: {
          categories: [],
          price: [],
        },
        selected: [],
        category: {},
        breadcrumbs: [
          {
            text: i18n.t('Accueil'),
            disabled: false,
            to: '/',
          },
        ],
      }
    },
    computed: {
      ...mapGetters(['getProducts', 'getCategory', 'getRatings']),
      path () {
        return this.$route.path
      },
      search () {
        return { ...this.query, ...this.filter.categories }
      },
      numberOfPages () {
        return Math.ceil(this.items.length / this.itemsPerPage)
      },
      categories () {
        if (this.category && this.category.categories) {
          return this.category.categories || []
        } else {
          return []
          //return this.$store.state.categories
        }
      },
    },
    watch: {
      path (val) {
        this.default_url = this.$route.path
        this.url = this.default_url
        this.items = []
        this.pagination = { totalItems: 0 }
        this.loading = false
        if (this.$route.path.includes('categories')) {
          this.setCategory()
        }
        this.loadData()
      },
      search: {
        handler () {
          clearTimeout(this.timeout)

          this.timeout = setTimeout(() => {
            this.loadData()
          }, 1300) // 1 sec delay
        },
        deep: true,
      },
    },
    created () {
      this.default_url = this.$route.path
      this.url = this.default_url
      // window.addEventListener('scroll', this.handleScroll)
      window.addEventListener('scroll', () => {
        this.bottom = this.bottomVisible()
        if (!this.loading && this.bottom && this.items.length < this.pagination.totalItems) {
          this.loadData()
        }
      })
      // this.items = this.getProducts
      // this.items = this.getProducts.slice(0, 15).map(n => n);
      if (this.$route.path.includes('categories')) {
        this.setCategory()
      }
      this.loadData()
    },
    destroyed () {
      this.setValue({
        type: 'breadcrumbs',
        value: [],
      })
      window.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
      ...mapActions(['addCart', 'removeCart', 'fetchProduct']),
      ...mapMutations([
        'pushItem', // map `this.increment()` to `this.$store.commit('increment')`
        'removeItem', // map `this.increment()` to `this.$store.commit('increment')`

        // `mapMutations` also supports payloads:
        'setValue', // map `this.incrementBy(amount)` to `this.$store.commit('incrementBy', amount)`
      ]),
      setCategory () {
        var item = this.$store.state.categories.find(ele => ele.slug === this.$route.params.slug)
        /*if (item != null) {
          this.category = item
          this.setValue({
            type: 'breadcrumbs',
            value: [...this.breadcrumbs, ...(this.category.breadcrumbs || []), {
              text: this.category.name,
              disabled: true,
              to: '/',
            }].filter(e => e.text),
          })
        } else {*/
          axios.get(this.$route.path, { params: { only: true } }).then((response) => {
            if (response.data && response.data.category) {
              this.category = { ...response.data.category, categories: response.data.categories }
              this.setValue({
                type: 'breadcrumbs',
                value: [...this.breadcrumbs, ...(this.category.breadcrumbs || []), {
                  text: this.category.name,
                  disabled: true,
                  to: '/',
                }].filter(e => e.text),
              })
              this.filters.categories = []
            }
          })
        //}
      },
      bottomVisible () {
        const scrollY = window.scrollY
        const visible = document.documentElement.clientHeight
        const pageHeight = document.documentElement.scrollHeight
        /* console.log('scrolly', scrollY)
        console.log('visible', visible) */
        return visible < scrollY
        /* const bottomOfPage = (visible + scrollY) - 1000 >= pageHeight
        return bottomOfPage || pageHeight < visible */
        const bottomOfPage = (visible + scrollY) - 1000 >= pageHeight
        return bottomOfPage || pageHeight < visible
      },
      loadData () {
        if (this.url != null) {
          this.pagination.page += 1
          this.getData()
        }
      },
      reLoadData () {
        this.url = this.default_url
        this.getData()
      },

      async getData () {
        this.loading = true

        return new Promise((resolve, reject) => {
          var _this = this

          // this.setValue({type:'posts',value:[]});

          axios({
            method: 'get',
            url: _this.url,
            params: {
              orderBy: this.order,
              categories: this.filter.categories,
              term: this.query,
              page: this.pagination.page || 1,
              /* direction: this.pagination.descending ? "DESC" : "ASC", */
              per_page: this.pagination.itemsPerPage,
            },
            data: {},
            validateStatus: (status) => {
              return true
            },
          }).then((response) => {
            if (response.data.error) {
              // this.toast(response.data.message, 'red');
              return false
            }
            /* if(!this.items[0].id){
              this.setValue({type:"user_posts",value:[]});
            } */
            const items = response.data.data
            const total = response.data.total
            this.loading = false

            this.pagination = {
              page: response.data.current_page,
              itemsPerPage:
                response.data.per_page,
              totalItems:
                total,
            }

            // this.items = data.items;

            _this.url = response.data.next_page_url || _this.url

            for (var i = 0; i < items.length; i++) {
              // _this.pushItem(items[i])

              var item = items[i]
              item = {
                ...item,
                title: item.name,
                categoryName: item.category ? item.category.name : '',
                amount: item.price_with_tax,
                original_price: item.price_with_tax,
                promo: item.promo,
                qty: 0,
              }
              item = this.processProduct(item)
              var index = this.items.findIndex(e => e.id === item.id)
              if (index < 0) {
                this.items.push(item)
              }
            }
            // _this.total += total;
            /* if (_this.bottomVisible() && this.loadable) {
                 _this.loadData()
             } */
          }).catch(error => {
            // this.toast("Error", "red");
            console.log(error)
          }).catch(error => {
            // this.toast(this.$t("Error"), "red");
            console.log(error)
          }).then(() => {
            _this.loading = false
          })
        })
      },
      relevance (amount) {
        if (this.select == 'Low to High') {
          this.items = this.items.sort((a, b) => a[amount] < b[amount] ? -1 : 1)
        } else {
          this.items = this.items.sort((a, b) => a[amount] > b[amount] ? -1 : 1)
        }
      },
      // fullProducts () {
      //   this.items = this.getProducts
      // },
      nextPage () {
        if (this.page + 1 <= this.numberOfPages) this.page += 1
      },
      formerPage () {
        if (this.page - 1 >= 1) this.page -= 1
      },
      season (val) {
        return this.range[val]
      },

      filterCategory (category) {
        this.items = this.getProducts.filter(x => this.selected.includes(x.category))
        if (this.selected.length <= 0) {
          this.items = this.getProducts
        }
      },
      filterRatings (rating) {
        this.items = this.getProducts.filter(x => this.selected.includes(x.rating))
        if (this.selected.length <= 0) {
          this.items = this.getProducts
        }
      },
      productRate (rate) {
        this.items = this.getProducts.filter(x => x.rating == rate.rates)
        // this.items = this.filterProduct.sort((a, b) => a[rate.rates] < b[rate.rates] ? -1 : 1)
      },
    },
  }
</script>

<style scoped lang="scss">
.filter {
  /*max-height: 400px;*/
  overflow-y: auto;
  .v-list-item{
    min-height: auto !important;
  }
}
.filter  .v-list-item__action{
  margin: 0 !important;
}
/* .filter .v-list-group--sub-group .v-list-item {
   padding-left: 16px !important;
 }*/
/*.filter .v-list-group--sub-group .v-list-group__header {
  padding-left: 8px !important;
}
.filter .v-list-group--sub-group .v-list-item__icon:first-child{
  margin-right: 0 !important;
}*/
</style>
